@import url("./normalize.css");

*,
:is(::before, ::after) {
	box-sizing: border-box;
}

:root {
	/* font-family: system-ui, ui-sans-serif, sans-serif; */
	font-size: 16px;
}

body {
	display: grid;
	grid-template-areas:
		"header"
		"nav"
		"main"
		"footer";
	grid-auto-rows: auto;
	grid-auto-columns: auto;
	gap: 8px;
	min-height: 100dvh;
	margin: 0;
	padding: 18px;
	max-width: 1200px;
	background-image: linear-gradient(
		135deg,
		hsla(17, 73%, 79%, 1) 0%,
		hsla(17, 80%, 31%, 1) 50%,
		hsla(17, 98%, 23%, 1) 51%,
		hsla(17, 47%, 53%, 1) 100%
	);
}

@media screen and (width >= 1024px) {
	:root {
		font-size: 18px;
	}
	body {
		display: grid;
		grid-template-areas:
			"header header"
			"nav		main"
			"footer footer";
		grid-template-rows: 320px 1fr 40px;
		grid-template-columns: 320px 1fr;
	}
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

ml-nav {
	grid-area: nav;
}

header {
	grid-area: header;
}

header img {
	display: block;
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

main {
	grid-area: main;
}

main,
footer {
	background-color: antiquewhite;
	padding: 4px;
}

footer {
	display: flex;
	align-items: center;
	grid-area: footer;
}

h1 {
	margin-block-start: 0;
}

figure {
	margin: 0;
}
