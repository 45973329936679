:where(html) {
  line-height: 1.15;
}

:where(h1) {
  margin-block: .67em;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin-block: 0;
}

:where(hr) {
  box-sizing: content-box;
  color: inherit;
  height: 0;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(table) {
  text-indent: 0;
  border-color: currentColor;
}

:where(button, input, select) {
  margin: 0;
}

:where(button) {
  text-transform: none;
}

:where(button, input:is([type="button" i], [type="reset" i], [type="submit" i])) {
  -webkit-appearance: button;
}

:where(progress) {
  vertical-align: baseline;
}

:where(select) {
  text-transform: none;
}

:where(textarea) {
  margin: 0;
}

:where(input[type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(button, input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i]))::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:where(button, input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i]))::-moz-focusring {
  outline: 1px dotted ButtonText;
}

:where(:-moz-ui-invalid) {
  box-shadow: none;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(summary) {
  display: list-item;
}

*, :is() {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  min-height: 100dvh;
  max-width: 1200px;
  background-image: linear-gradient(135deg, #f1b9a2 0%, #8e3410 50%, #742201 51%, #bf6f4f 100%);
  grid-template-areas: "header"
                       "nav"
                       "main"
                       "footer";
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  gap: 8px;
  margin: 0;
  padding: 18px;
  display: grid;
}

@media screen and (min-width: 1024px) {
  :root {
    font-size: 18px;
  }

  body {
    grid-template: "header header" 320px
                   "nav main" 1fr
                   "footer footer" 40px
                   / 320px 1fr;
    display: grid;
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

ml-nav {
  grid-area: nav;
}

header {
  grid-area: header;
}

header img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  display: block;
}

main {
  grid-area: main;
}

main, footer {
  background-color: #faebd7;
  padding: 4px;
}

footer {
  grid-area: footer;
  align-items: center;
  display: flex;
}

h1 {
  margin-block-start: 0;
}

figure {
  margin: 0;
}

/*# sourceMappingURL=index.0190f56c.css.map */
